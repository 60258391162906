import { canDeviceHover, isDeviceWidthBelow } from '../utils/device';

const initBlock = ( $block ) => {
	const $dropdownTrigger = $block.find( '.js-dropdown-trigger' );
	const $subMenuButton = $block.find( '.menu-item-has-children > a > .menu-item-icon' );
	const $contentTrigger = $block.find( '.js-content-trigger' );
	const $headerContent = $block.find( '.js-header-content' );

	/**
	 * Close sub-menu.
	 *
	 * @param {jQuery} $subMenuAnchor
	 */
	const closeSubMenu = ( $subMenuAnchor ) => {
		$subMenuAnchor
			.removeClass( 'is-active' )
			.siblings( '.sub-menu' )
			.slideUp()
			.removeClass( 'is-active' );
	};

	/**
	 * Toggle header content.
	 *
	 * @param {jQuery} $content
	 * @param {boolean} state - true to open, false to close
	 */
	const toggleHeader = ( $content, state ) => {
		$content.toggleClass( 'is-active', state );
		$( 'html' ).toggleClass( 'is-locked', state );
	};

	/**
	 * Handle dropdown trigger click.
	 */
	$dropdownTrigger.on( 'click', function( event ) {
		event.preventDefault();

		if ( canDeviceHover() ) {
			return;
		}

		const $trigger = $( this );
		const $content = $trigger.next();

		$trigger.toggleClass( 'is-active' );
		$content.toggleClass( 'is-active' );
	} );

	/**
	 * Handle sub-menu trigger click.
	 */
	$subMenuButton.on( 'click', function( event ) {
		if ( !isDeviceWidthBelow( 1441 ) ) {
			return;
		}

		event.preventDefault();

		const $trigger = $( this );
		const $anchor = $trigger.parent();
		const $content = $anchor.siblings( '.sub-menu' );

		$anchor.toggleClass( 'is-active' );
		$content.toggleClass( 'is-active' ).slideToggle();

		closeSubMenu( $anchor.parent().siblings().find( 'a' ) );
	} );

	/**
	 * Handle content trigger click.
	 */
	$contentTrigger.on( 'click', function( event ) {
		event.preventDefault();

		toggleHeader( $headerContent );
		closeSubMenu( $headerContent.find( '.menu-item-has-children > a' ) );
	} );

	/**
	 * Handle header content click.
	 */
	$headerContent.on( 'click', function( event ) {
		const $target = $( event.target );
		const $content = $( this );
		const $contentInner = $target.find( '.header__content-inner' );

		if ( $contentInner.length ) {
			toggleHeader( $content );
			closeSubMenu( $content.find( '.menu-item-has-children > a' ) );
		}
	} );

	/**
	 * Handle document click and scroll to close dropdowns.
	 */
	$( document ).on( 'click scroll', function( event ) {
		const $target = $( event.target );
		const hasClosestHeader = !!$target.closest( '.header' ).length;

		if ( hasClosestHeader ) {
			return;
		}

		$dropdownTrigger
			.removeClass( 'is-active' )
			.next()
			.removeClass( 'is-active' );
	} );

	/**
	 * Handle window resize.
	 */
	$( window ).on( 'resize', () => {
		toggleHeader( $headerContent, false );
	} );
};

app.loadBlock( initBlock, 'header', '.header' );
