/**
 * Check if device can hover.
 *
 * @returns {boolean}
 */
export const canDeviceHover = () => {
	return window.matchMedia( '(hover: hover) AND (pointer: fine)' ).matches;
};

/**
 * Check if device width is less than or equal to specified width.
 *
 * @param {number} maxWidth Maximum width to check against in pixels
 * @returns {boolean} True if device width is less than or equal to maxWidth
 */
export const isDeviceWidthBelow = ( maxWidth = 1024 ) => {
	return window.matchMedia( `(max-width: ${maxWidth}px)` ).matches;
};
